import React from "react";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import { CoinsIcon } from "./svgIcons";
import { t } from "i18next";

export interface RowConsultantPriceProps {
  imagePath: string;
  text: string;
  price: number;
}

const useStyles = makeStyles({
  generalDiv: {
    overflow: "hidden",
    padding: "0 25px"
  },
  divRight:{
    float: "right",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "8px 0px",
  },
  divLeft:{
    float: "left",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "8px 0px",
  },
  iconContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  iconCoin: {
    //transform: "scale(0.8)",
  },
  text: {
    paddingLeft: "10px"
  },
  price: {
    paddingRight: "10px"
  }
});
const RowConsultantPrice = ({ text, imagePath, price }: RowConsultantPriceProps) => {
  const classes = useStyles();

  return (
    <div className={classes.generalDiv}>
      <div className={classes.divLeft}>
        <div className={classes.iconContainer}>
          <img src={imagePath} width={30}></img>
        </div>
          <Typography className={classes.text} variant="h6" color="text.secondary" fontSize={16} fontWeight={400}>
            {text}
          </Typography>
      </div>
      <div className={classes.divRight}>
        <Typography className={classes.price} variant="body2" color="text.secondary" fontSize={14}>
          {price != 0 ? price : t("free")}
        </Typography>
        <CoinsIcon className={classes.iconCoin} />
      </div>
    </div>
  );
};

export default RowConsultantPrice;

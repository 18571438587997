import React from "react";

export function LiveIcon(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={12} {...props}>
      <path
        fill="#777"
        d="M10.506 0H1.494A1.494 1.494 0 000 1.494v9.012A1.494 1.494 0 001.494 12h9.013A1.494 1.494 0 0012 10.506V1.494A1.494 1.494 0 0010.506 0zm3.919 1.178L11 3.541V8.46l3.425 2.359A1 1 0 0016 10.012V1.984a1 1 0 00-1.575-.806z"
      />
      <path
        fill="#fff"
        d="M9.659 5.81A4.176 4.176 0 005.954 3.5a4.177 4.177 0 00-3.7 2.31.421.421 0 000 .38 4.176 4.176 0 003.7 2.31 4.177 4.177 0 003.7-2.31.421.421 0 00.005-.38zM5.954 7.875A1.875 1.875 0 117.829 6a1.875 1.875 0 01-1.875 1.875z"
      />
    </svg>
  );
}

export function ClassRoomIcon(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} {...props}>
      <circle cx={7} cy={7} r={7} fill="#777" />
      <path
        fill="#fff"
        d="M7.628 4.5H3.872a.622.622 0 00-.622.622v3.756a.622.622 0 00.622.622h3.756a.622.622 0 00.622-.622V5.122a.622.622 0 00-.622-.622zm2.466.491l-1.427.984v2.05l1.427.983a.417.417 0 00.656-.336V5.327a.418.418 0 00-.656-.336z"
      />
    </svg>
  );
}

export function ParticipantsIcon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16.775}
      height={12.64}
      {...props}
    >
      <defs>
        <style>
          {
            ".icon_participants_svg__cls-1{fill:#777;stroke-linejoin:round}.icon_participants_svg__cls-2,.icon_participants_svg__cls-3{stroke:none}.icon_participants_svg__cls-3{fill:#fff}"
          }
        </style>
      </defs>
      <g
        id="icon_participants_svg__Partecipanti"
        transform="translate(-30 -309)"
      >
        <g
          id="icon_participants_svg__ACCOUNT"
          className="icon_participants_svg__cls-1"
        >
          <path
            d="M393.234 2325.993h-6.579c-1.061 0-1.802-.741-1.802-1.802 0-1.138.683-1.836 3.093-3.025a3.322 3.322 0 01-.696-2.069c0-1.593 1.153-2.841 2.624-2.841h.142c1.446 0 2.623 1.275 2.623 2.841 0 .892-.387 1.603-.725 2.054 2.433 1.199 3.122 1.897 3.122 3.04 0 1.061-.74 1.802-1.802 1.802z"
            className="icon_participants_svg__cls-2"
            transform="translate(-348.761 -2005.804)"
          />
          <path
            d="M389.874 2316.756c-1.174 0-2.124 1.022-2.124 2.341 0 1.5 1.043 2.215 1.043 2.215-3.02 1.428-3.44 2.033-3.44 2.879 0 .846.583 1.302 1.302 1.302h6.58c.718 0 1.301-.456 1.301-1.302 0-.846-.42-1.451-3.44-2.878 0 0 1.043-.88 1.043-2.216 0-1.291-.95-2.341-2.123-2.341h-.142m0-1h.142c1.722 0 3.123 1.499 3.123 3.341 0 .746-.228 1.375-.5 1.861.933.484 1.55.882 1.98 1.27.634.572.917 1.178.917 1.963 0 1.334-.968 2.302-2.302 2.302h-6.579c-1.334 0-2.302-.968-2.302-2.302 0-1.372.895-2.198 2.87-3.22a3.905 3.905 0 01-.473-1.874c0-.885.314-1.716.884-2.34a3.01 3.01 0 012.24-1.001z"
            className="icon_participants_svg__cls-3"
            transform="translate(-348.761 -2005.804)"
          />
        </g>
        <g
          id="icon_participants_svg__ACCOUNT-2"
          className="icon_participants_svg__cls-1"
        >
          <path
            d="M394.95 2327.896h-8.011c-1.228 0-2.086-.858-2.086-2.086 0-1.353.842-2.184 3.828-3.646a3.924 3.924 0 01-.909-2.557c0-.899.316-1.739.888-2.365a2.954 2.954 0 012.199-.986h.172c1.702 0 3.086 1.503 3.086 3.351 0 1.13-.524 2.014-.942 2.54 3.013 1.473 3.862 2.305 3.862 3.663 0 1.228-.858 2.086-2.086 2.086z"
            className="icon_participants_svg__cls-2"
            transform="translate(-354.353 -2006.756)"
          />
          <path
            d="M390.859 2316.756c-1.43 0-2.587 1.244-2.587 2.851 0 1.826 1.27 2.698 1.27 2.698-3.676 1.738-4.189 2.475-4.189 3.505 0 1.03.71 1.586 1.586 1.586h8.012c.876 0 1.586-.555 1.586-1.586 0-1.03-.513-1.767-4.19-3.505 0 0 1.27-1.07 1.27-2.698 0-1.573-1.157-2.851-2.586-2.851h-.172m0-1h.172c1.977 0 3.586 1.728 3.586 3.851 0 .96-.336 1.762-.702 2.352 2.779 1.417 3.622 2.364 3.622 3.851 0 1.498-1.088 2.586-2.586 2.586h-8.012c-1.499 0-2.586-1.088-2.586-2.586 0-1.48.835-2.425 3.58-3.83a4.527 4.527 0 01-.66-2.373c0-1.024.361-1.984 1.018-2.703a3.451 3.451 0 012.568-1.148z"
            className="icon_participants_svg__cls-3"
            transform="translate(-354.353 -2006.756)"
          />
        </g>
      </g>
    </svg>
  );
}

export function CoinsIcon(props: any) {
  const { size,...otherProps } = props;
    let defSize = 40;

    if (!!size) {
        defSize = size;
    }
  return (
    <svg
      data-name="Coin small"
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      {...props}
    >
      <defs>
        <clipPath id="icon_coin_svg__a">
          <path data-name="Rettangolo 1099" d="M0 0h20v20H0z" fill="none" />
        </clipPath>
      </defs>
      <g data-name="Raggruppa 777" clipPath="url(#icon_coin_svg__a)">
        <path
          data-name="Tracciato 294"
          d="M10 0A10 10 0 110 10 10 10 0 0110 0"
          fill="#ffa700"
        />
        <path
          data-name="Tracciato 295"
          d="M10 1.833a8.417 8.417 0 11-8.417 8.417A8.417 8.417 0 0110 1.833z"
          stroke="#fff"
          strokeMiterlimit={10}
          strokeWidth={0.8}
          fill="none"
        />
        <path
          data-name="Tracciato 296"
          d="M14.113 10.441a16.56 16.56 0 00-.559-1.283c-.1-.2-.19-.4-.28-.6-.072-.16-.145-.3-.212-.431a1.358 1.358 0 01-.029-1.61l.03-.067-.024-.05c-.038-.078-.135-.094-.3-.117-.043-.005-.077-.01-.091-.01a1.275 1.275 0 01-.116-.354c-.043-.183-.062-.259-.151-.266h-.084l-.028.068a2.526 2.526 0 00-.107 1.6 8.557 8.557 0 00.866 2.047 7.143 7.143 0 01.907 2.336A1.646 1.646 0 0113 13.468a1.3 1.3 0 01-1.356-.217 2.991 2.991 0 01-.677-.716 4.594 4.594 0 00.215-.407 3.611 3.611 0 00.32-1.572 1.609 1.609 0 00-.633-1.443.984.984 0 00-1.269.565 2.5 2.5 0 00-.232 1.55 3.723 3.723 0 00.586 1.458c.046.069.114.161.2.275a2.762 2.762 0 01-.687.551 1.3 1.3 0 01-1.687-.162.947.947 0 01-.167-.657 4.841 4.841 0 01.424-1.517c.126-.3.276-.589.421-.875a11.669 11.669 0 00.513-1.1 2.283 2.283 0 00.075-1.647 1.678 1.678 0 00-1.1-.974 1.926 1.926 0 00-.763-.071 1.265 1.265 0 00-.8.3 1.881 1.881 0 00-.735 1.841 1.261 1.261 0 00.187.317 1.276 1.276 0 00.649.423 1.2 1.2 0 001.36-.582.8.8 0 00.08-.64.435.435 0 00-.284-.255.541.541 0 00-.589.195 1.044 1.044 0 00-.087.148c-.06.114-.1.18-.2.2a.268.268 0 01-.282-.1.63.63 0 01-.063-.587 1.2 1.2 0 01.949-.638 1.044 1.044 0 011.051.606A1.686 1.686 0 018.17 9.13l-.167.29c-.117.2-.234.409-.346.609a5.636 5.636 0 00-.945 2.286 2.792 2.792 0 00.012.771 1.772 1.772 0 001.83 1.262A1.714 1.714 0 008.9 14.3a2.468 2.468 0 00.588-.238 3.773 3.773 0 00.943-.76 2.855 2.855 0 001.9.946 2.162 2.162 0 002.029-1.893 4.418 4.418 0 00.04-.655 4.362 4.362 0 00-.292-1.255m-3.98.3a1.636 1.636 0 01.011-.74c.045-.163.178-.438.395-.438.2 0 .329.281.372.4a2.281 2.281 0 01.118.893 3.01 3.01 0 01-.309 1.336 4.441 4.441 0 01-.585-1.445l-.1.021z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

export function RatingTechFullIcon(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={17} height={35} {...props}>
      <image
        width={17}
        height={35}
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADMAAABpCAMAAACgauuJAAAABGdBTUEAALGPC/xhBQAAACBjSFJN AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAA/FBMVEUAAAD/M5nkKHnkJ3nk J3rkJnnjJ3noKXrjJ3rkJnrjJnrmKXv/QIDkJ3njJ3rkJ3nmKXvnKn3jJ3rlJ3r/VarjJ3rjJnnk J3nmJnnkJnnkJ3r/gIDkKHrjKXzkJ3r////jJ3rjJnnpLYDjJnrlJnvnKIDkKHvkJ3nlLHvkJnrl J3rjJnrsL3vkJ3rkKXrjJnroLovkJnrkJ3rlJnvkJ3rmJ3vjKHzxK4DmKXrkKHzkKHvkKHrlKHvk Jnr/SZLlJ3nrKXrjJnrlJ3zkJnnvMIDkJ3rkJnrmKHrkK3rlJnrkJ3njJnnkJnrjJ3rjJnrjKn3k JnnkKXvjJnn///9fUVEJAAAAUnRSTlMABWfM9emkLC7P91EEfPn0HyvLiAN3+L0oyNECcyXFAW/2 IsFqIGbyHbpi8Ru2Xu8LrbxXrDRAEjJMX2B0/Qd2Gckn4xDatEcwf7/b4q+uN/w41T+fPgAAAAFi S0dEHwUNEL0AAAAHdElNRQfmBxQOBBVivUICAAABLElEQVRYw+3Wx1bCUBSF4aPYgiV2FDHB3o0N KdJ7EQn7/R9GIwuia2WZu5k4uf/4fKMz2SKhzcxG5uYXFsMP/ZYMeEWXlcXK6hpGmeuKZGMTk7aU xPbOrk8QUyF7+/hZPFwcJKIgzaEFcMZOHoE0xycAZ+xTE6Q5iwGkOb8AaS6vANJc34A0t3cAae4d kObhESCN/QTaPIeRAPMyhYlMYeLaaKONNtpMSr2GlRJdUOlMNvcWXC6bSQeAvFX48zsFK/+bFUtO 6EsBp1T0SbmiILwq5TGp1hQJUKuOSL2hTIBG/ds0CQI0PdKiCND6MgZpDJE2SYC2dGjTkS5tutKj TU9M2phCE0AbbbTRRhtt/sWo75Zx79KnzYcMXJK4A5EEh9yEN3iGfWKL9Ycin8RA3Um+RQ0BAAAA JXRFWHRkYXRlOmNyZWF0ZQAyMDIyLTA3LTIwVDEyOjA0OjIxKzAyOjAwBi2E+wAAACV0RVh0ZGF0 ZTptb2RpZnkAMjAyMi0wNy0yMFQxMjowNDoyMSswMjowMHdwPEcAAAAASUVORK5CYII="
      />
    </svg>
  );
}

export function RatingTechEmptyMagenta(props:any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={17} height={35} {...props}>
      <image
        width={17}
        height={35}
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADMAAABpCAQAAAA9vdMwAAAABGdBTUEAALGPC/xhBQAAACBjSFJN AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElN RQfmBxQOAif8MLQEAAADbElEQVRo3u3ZS0gUcRgA8O+bXe3hIfM5uxmIIUVGVJhagkgpFgRaUKfw FSRFYFCiguGhhwVdOngKK8Jbh+qUSXQoJJWtJRMpJCmUmVkfpcT6WHf361I6L3dnZ0YL+X97mv/M fr/5/jM7j28BbAiKE+oEj+AXfUKntMeOjDrh2yt4BVr6zPlOardBi3U4xSa4BvGKlH7KcX9XbsdZ QcQc6R1cVyIAlIAX1FuaZsghNtJ7ytVdeVA94DSHTO6SHlH+iqtTbKiGOPFKwKtGMBjpOzEz49ni W7oLG1XD3VBhG0Mo1Qc/wmFVHb+gzl3GjUb6ZgzHxpclPaQi9Si+plr16Wu6GkLxYnhAjaAfL/El 0RGD1YiZUgcd0dTxhqtJHzG2mwaqEc7DgAaZ5S7zxUaRqNVMbV/ogFJSD/c4a9KGjRJRq5FqA4NQ qhqcx6uuotiQCNVMblu8Hz6uHsW+uOqUz7EREaoRKwODpEYWsIkvNIPoViMl0GM6panDA1WuITOE bjWE9FyDBKCFP2Qe0anGd4aOqoa8XBX/yTyhy9BpxVQtwk3+Fi5aQ/SYTBky4KhO81oldBmMW/4x cpVpH+1Aol1syGAWi4xtwRjGMIYxjGHMWkTEZ+hwh+g3miicYJpZ4T3ZRPyrY0Mz1pNqc2ir6bZh 5zU5ND0bipc6lU+esQY+4c9iIAoDADCVEdhhsmlE8V+Tx2yYDxbrLXTPp5mkQGbITckxnm2EUw4h /tuWH1GZmaTZc1SBBWT6IoRh6sWnmx8oMRlDG6R6aoZEW2ZpGtv4e7igYSZcwWeUZwvxN3W/syJV VDATOxdfQYadCAAAjMWVpH5ZYn4mzvVDtu0IAMDwpryt039ua/OdagRHYAimYk6aDLspSzGSPd8J JwAQwFcW6lIQL5wtqR/M7v7EgeANZbfHcSz9JQAoOvwkNlifKbFBnlHwAuD4vqC8wXDb3WydARDa oGl5ybmfC5XLpmvE1WoHAuBqRVmDMlTOQfHyIrWr73pmAwPULlss5uS/FuyKOd/KkDxXBgcu2SoD HWXDjDyXCwVZX8Zt8U8jZcgzr69HdcYwhjGMYQxjGMMYxjCGMf8lo3i/Wc1q1qIBOsqBZw0YD0r5 1EOO1TQwhIUc3weNGFpNBBr5PgQAkArCjZC7Go0u8HB3+F6A3wgbLk+mahXJAAAAJXRFWHRkYXRl OmNyZWF0ZQAyMDIyLTA3LTIwVDEyOjAyOjM5KzAyOjAw9Ha6RQAAACV0RVh0ZGF0ZTptb2RpZnkA MjAyMi0wNy0yMFQxMjowMjozOSswMjowMIUrAvkAAAAASUVORK5CYII="
      />
    </svg>
  );
}
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';


import './index.scss';

const ResponsiveAppBar = () => {
  

  return (
    <AppBar elevation={0} className="header" position="static" style={{ background: '#FFFFFF',borderBottom: "1px solid lightgray" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <div className='toolbar-container'>
            <img className='logo' src="/assets/images/logo_toolbar@2x.png"></img>
          </div>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
import "./index.scss";
import React from "react";

const Splashscreen = () => {
  React.useEffect(() => {}, []);

  return <div className="screen splashscreen">
    <img className="logo" src="./assets/images/logo@2x.png"></img>
  </div>;
};

export default Splashscreen;

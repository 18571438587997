import React from "react";
import { Button, Rating, Typography, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Theme, useTheme } from "@material-ui/core";
import { makeStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import { isAndroid, isDesktop, isIOS } from "react-device-detect";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import {RatingTechFullIcon, RatingTechEmptyMagenta} from "./svgIcons";
import RowConsultantPrice from "./rowConsultantPrice";
import { useTranslation } from 'react-i18next';

const technicalRatingTotal = 5;
const qualityRatingTotal = 5;

const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "rgb(227, 38, 121)",
  },
  "& .MuiRating-iconHover": {
    color: "#ff3d47",
  },
});

const useStyles = makeStyles<Theme>((theme) => ({
  card: {
    marginTop: 20,
    maxWidth: 440,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      width: "100%",
      marginTop: 0,
      marginBottom: 0,
    },
    marginBottom: 20,
  },
  cardHeader:{
    position: "relative",
    marginBottom: 40,
  },
  separatorGray: {
    height: 1,
    background: "lightgray",
    margin: "4px 20px 16px 20px",
  },
  separatorFucsia: {
    height: 2,
    background: "#e32679",
    margin: "0px 0px",
  },
  iconCoin: {
    transform: "scale(0.8)",
  },
  imageContainer: {
    width: 104,
    height: 104,
    background: "#FFFFFF",
    borderRadius: "50%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    bottom: "-48px",
    left: "calc(50% - 48px)",
    right: "50%"
  },
  profileImage: {
    borderRadius: "50%",
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: 20,
  },
  jobRole: {
    marginTop: 10,
    padding: "2px 8px",
    borderRadius: 4,
    background: "#002049",
    minHeight: 20,
    color: "#FFFFFF",
    textTransform: "uppercase",
  },
  ratingObject: {
    padding: "12px 0"
  }
}));

export interface ConsultantViewProps {
  consultant: Service.Consultants.Consultant;
}

const ConsultantView = ({ consultant }: ConsultantViewProps) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);

  const isSmartScreen = useMediaQuery(theme.breakpoints.down(600));
  console.log("IS SMARTPHONE: ", isSmartScreen);

  const onClickOpenApp = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    let url = "";
    if (isDesktop) {
      url = "https://www.mybeautywhisper.com";
    }

    if (isIOS) {
      url = "https://apps.apple.com/it/app/mybeautywhisper/id1491000724";
    }

    if (isAndroid) {
      url = "https://play.google.com/store/apps/details?id=com.mybeautywhisper";
    }

    window.open(url, "_blank");
  };

  const buttonLabel = isDesktop ? t('lookup') : t('download_app');
  const jobRole =
    !!consultant.jobRole && consultant.jobRole.length > 0
      ? consultant.jobRole
      : "Beauty expert";
  let skillsString = "";
  if(!!consultant.primarySkill){
    skillsString = t(consultant.primarySkill.replaceAll(" ", "_"));
  }
  if(!!consultant.secondarySkill){
    if(skillsString.length > 0){
      skillsString = skillsString + " " + t('and') + " " + t(consultant.secondarySkill.replaceAll(" ", "_"));
    }else{
      skillsString = t(consultant.secondarySkill.replaceAll(" ", "_"))
    }
  }
  let pictureUrl = "/assets/images/bkg_small_avatar.png";
  if(!!consultant.picture){
    pictureUrl = `https://static.mybeautywhisper.com/400x400/${consultant.picture}`;
  }
  return (
    <Card
      raised={!isSmartScreen}
      square={isSmartScreen}
      className={classes.card}
    >
      <div className={classes.cardHeader}>
        <CardMedia
          component="img"
          height="220"
          src={"/assets/images/consultants_profile_header@3x.png"}
        />
        <div className={classes.imageContainer}>
          <img
            className={classes.profileImage}
            src={pictureUrl}
            height="96"
            width="96"
          />
        </div>
        <div className={classes.separatorFucsia} />
      </div>

      <CardContent className={classes.cardContent}>
        <Typography variant="body1" color="text.primary" fontSize={18} fontWeight={600}>
          {consultant.fullName}
        </Typography>
        <div className={classes.jobRole}>
          <Typography variant="caption">{jobRole}</Typography>
        </div>
      </CardContent>
      <CardContent className={classes.cardContent}>
        <Typography variant="h6" color="text.primary" fontSize={16} fontWeight={800}>
          {t('advice_quality').toUpperCase()}
        </Typography>
        <StyledRating
          className={classes.ratingObject}
          name="reputationQuality"
          defaultValue={consultant.reputationQuality}
          getLabelText={(value: number) =>
            `${value} Heart${value !== 1 ? "s" : ""}`
          }
          precision={0.1}
          icon={<FavoriteIcon fontSize="inherit" style={{margin:'0px 1px'}}/>}
          emptyIcon={<FavoriteBorderIcon fontSize="inherit" style={{margin:'0px 1px'}}/>}
          readOnly
          max={technicalRatingTotal}
        />
        <Typography variant="h6" color="text.primary" fontSize={16} fontWeight={800}>
          {t('technical_preparation').toUpperCase()}
        </Typography>
        <StyledRating
          className={classes.ratingObject}
          name="reputationTechnical"
          defaultValue={consultant.reputationTechnical}
          getLabelText={(value: number) => `${value} Lipstick${value !== 1 ? 's' : ''}`}
          precision={0.1}
          icon={<RatingTechFullIcon fontSize="inherit" style={{margin:'0px 5px'}}></RatingTechFullIcon>}
          emptyIcon={<RatingTechEmptyMagenta fontSize="inherit" style={{margin:'0px 5px'}}></RatingTechEmptyMagenta>}
          readOnly
          max={qualityRatingTotal}
        />
      </CardContent>

      <CardContent>
        <Typography variant="body1" color="text.primary" fontSize={14} fontWeight={600}>
          {skillsString}
        </Typography>
        <Typography variant="body1" color="text.primary" fontSize={13}>
          {consultant.description}
        </Typography>
      </CardContent>
      <RowConsultantPrice imagePath={"/assets/images/ic_phone.png"} text={t('call').toUpperCase()} price={consultant.prices.call}/>
      <div className={classes.separatorGray} />
      <RowConsultantPrice imagePath={"/assets/images/ic_action_video.png"} text={t('videocall').toUpperCase()} price={consultant.prices.videocall}/>
      <div className={classes.separatorGray} />
      <CardContent>
        <Button
          variant="contained"
          style={{ background: "#e32679" }}
          fullWidth={true}
          onClick={onClickOpenApp}
        >
          {buttonLabel.toUpperCase()}
        </Button>
      </CardContent>
    </Card>
  );
};

export default ConsultantView;

import React from "react";
import moment from "moment";
import {
  Button,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Theme, useTheme } from "@material-ui/core";
import { makeStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import RowImageText from "./rowImageText";
import {
  LiveIcon,
  ClassRoomIcon,
  CoinsIcon,
  ParticipantsIcon,
} from "./svgIcons";
import { isAndroid, isDesktop, isIOS } from "react-device-detect";
import { useTranslation } from 'react-i18next';

const locale = window.navigator.language;

const useStyles = makeStyles<Theme>((theme) => ({
  card: {
    marginTop: 20,
    maxWidth: 440,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      width: "100%",
      marginTop: 0,
    },
  },
  separatorGray: {
    height: 1,
    background: "lightgray",
    margin: "4px 0px 16px 0px",
  },
  separatorFucsia: {
    height: 2,
    background: "#e32679",
    margin: "0px 0px",
  },
  iconCoin: {
    transform: "scale(0.8)",
  },
}));

export interface EventViewProps {
  event: Service.Events.Event;
}

const EventView = ({ event }: EventViewProps) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);

  const isSmartScreen = useMediaQuery(theme.breakpoints.down(600));
  console.log("IS SMARTPHONE: ", isSmartScreen);

  const convertDate = ({ date }: { date?: any }) => {
    if (!date) {
      return "";
    }

    if (typeof date === "string" || date instanceof String) {
      return date;
    } else {
      return date.toDate();
    }
  };

  let eventTypeString = "";
  switch (event.type) {
    case "meeting_room":
      const availableSeats = event.maxParticipants - event.counterParticipants;
      eventTypeString = t("event_meeting_room") + " (" + availableSeats + " " + t("available") + ")";
      break;
    default:
      eventTypeString = t("event_live_streaming");
      break;
  }

  const freeSeats = event.freeSeats ?? false;
  const isLive = event.type === "live_streaming";
  const isRoom = event.type === "meeting_room";

  let endSubscriptionString = "";

  if (!!event.endSubscriptionDateTime) {
    endSubscriptionString =
      moment(convertDate({ date: event.endSubscriptionDateTime }))
        .locale(locale)
        .format("ddd, D MMMM • ") +
      moment(convertDate({ date: event.endSubscriptionDateTime }))
        .locale(locale)
        .format("HH:mm");
  }

  const onClickOpenApp = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    let url = "";
    if (isDesktop) {
      url = "https://www.mybeautywhisper.com";
    }

    if (isIOS) {
      url = "https://apps.apple.com/it/app/mybeautywhisper/id1491000724";
    }

    if (isAndroid) {
      url = "https://play.google.com/store/apps/details?id=com.mybeautywhisper";
    }

    window.open(url, "_blank");
  };

  const buttonLabel = isDesktop ? t('lookup') : t('download_app');

  return (
    <Card raised={!isSmartScreen} square={isSmartScreen} className={classes.card}>
      <CardMedia
        component="img"
        height="220"
        image={event.cover}
        alt={event.title}
      />
      <div className={classes.separatorFucsia} />
      <CardContent>
        <Typography variant="caption" style={{ color: "#e32679" }}>
          {moment(convertDate({ date: event.startDateTime }))
            .locale(locale)
            .format("ddd, D MMMM • ") +
            moment(convertDate({ date: event.startDateTime }))
              .locale(locale)
              .format("HH:mm")}
        </Typography>
        <Typography variant="body1" color="text.primary">
          {event.title}
        </Typography>
        {isLive && (
          <RowImageText
            text={eventTypeString}
            svgIcon={<LiveIcon />}
          ></RowImageText>
        )}

        {isRoom && (
          <RowImageText
            text={eventTypeString}
            svgIcon={<ClassRoomIcon />}
          ></RowImageText>
        )}

        <div className={classes.separatorGray} />

        {isRoom && !freeSeats && (
          <RowImageText
            text={event.counterParticipants + " " + t("participants")}
            svgIcon={<ParticipantsIcon />}
          ></RowImageText>
        )}

        {event.paid && (
          <RowImageText
            text={event.coins + " " + t("event_price")}
            svgIcon={<CoinsIcon className={classes.iconCoin} />}
          ></RowImageText>
        )}

        {!event.paid && (
          <RowImageText
            text={t("event_free_of_charge")}
            svgIcon={<CoinsIcon className={classes.iconCoin} />}
          ></RowImageText>
        )}

        {isLive && (
          <RowImageText
            text={t("event_open")}
            imagePath="/assets/images/icon_event_open.png"
          ></RowImageText>
        )}

        {isRoom && freeSeats && (
          <RowImageText
            text={event.maxParticipants + " " + t("event_close")}
            imagePath="/assets/images/icon_event_closed.png"
          ></RowImageText>
        )}

        {isRoom && !freeSeats && (
          <RowImageText
            text={t("event_close")}
            imagePath="/assets/images/icon_event_closed.png"
          ></RowImageText>
        )}

        {isRoom && (
          <RowImageText
            text={t("event_subscription_deadline") + " " + endSubscriptionString}
            imagePath="/assets/images/icon_time.png"
          ></RowImageText>
        )}

        <div className={classes.separatorGray} />
        <Typography
          variant="body2"
          color="text.primary"
          style={{ marginBottom: 6 }}
        >
          {t("event_details")}
        </Typography>
        <Typography variant="body2" color="text.secondary" style={{lineHeight:1.7}}>
          {event.description}
        </Typography>
      </CardContent>
      <div className={classes.separatorGray} />
      <CardContent>
        <Button
          variant="contained"
          style={{ background: "#e32679" }}
          fullWidth={true}
          onClick={onClickOpenApp}
        >
          {buttonLabel.toUpperCase()}
        </Button>
      </CardContent>
    </Card>
  );
};

export default EventView;

import './index.scss';
import React from 'react';
import useMounted from '../../use-mounted';
import ResponsiveAppBar from '../header';


const Homescreen = () => {
    
    const isMounted = useMounted();

    React.useEffect(() => {
        return () => {
        };
    },[isMounted]);

  
    return (<div className="screen home">
        <ResponsiveAppBar />
        <main className="content">
        </main>
    </div>
    )


}

export default Homescreen;

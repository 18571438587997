import * as React from "react";
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  RouteProps,
} from "react-router-dom";
import Homescreen from "./core/components/home";
import * as firebase from "./configuration/firebase";
import Splascreen from "./core/components/splashscreen";
import LoginScreen from "./core/login";
import "./App.scss";
import Eventscreen from "./core/components/event";
import ConsultantScreen from "./core/components/consultant";

interface MenuItem {
  title: string;
  path?: string;
  component?: React.ReactNode | undefined;
  private: boolean;
}

const Menus: Array<MenuItem> = [
  {
    title: "Home",
    path: "/home",
    private: false,
    component: <Homescreen />,
  },
  {
    title: "Login",
    path: "/login",
    private: false,
    component: <LoginScreen />,
  },
  {
    title: "Event",
    path: "/event/:id?",
    private: false,
    component: <Eventscreen />,
  },
  {
    title: "Consultant",
    path: "/consultant/:id?/:countryBrandId?",
    private: false,
    component: <ConsultantScreen />,
  },
  {
    title: "Splashscreen",
    path: "*",
    private: false,
    component: <Splascreen />,
  },
];

interface IPrivateRouteProps extends RouteProps {}

const PrivateRoute: React.FC<IPrivateRouteProps> = (props) => {
  const auth = firebase.getAuth();
  const currentUser = auth.currentUser;
  console.log("@@@ PrivateRoute");
  console.log(currentUser);
  if (!!currentUser) {
    return <Route {...props} component={props.component} render={undefined} />;
  }

  return <Redirect to={{ pathname: "/home" }} />;
};

const ROUTES = Menus.map((menu: MenuItem) => {
  if (!!menu.component && !!menu.path) {
    if (menu.private) {
      return (
        <PrivateRoute
          key={menu.path}
          path={menu.path}
          children={menu.component}
          exact
        ></PrivateRoute>
      );
    }

    return (
      <Route
        key={menu.path}
        path={menu.path}
        children={menu.component}
        exact
      ></Route>
    );
  }
  return null;
});

export const App = () => {
  const [retriveUser, setRetrieveUser] = React.useState<boolean>(false);

  React.useEffect(() => {
    const auth = firebase.getAuth();
    auth.onAuthStateChanged((user) => {
      console.log(user);
      if (user) {
        const uid = user.uid;
        console.log(`LoggedIn with [${uid}]`);
      } else {
        console.log(`Not logged in`);
      }
      setRetrieveUser(true);
    });
  }, []);

  return (
    <div>
      <div>
        {retriveUser && (
          <BrowserRouter>
            <Switch>{ROUTES}</Switch>
          </BrowserRouter>
        )}
      </div>
    </div>
  );
};

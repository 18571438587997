import React from "react";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";

export interface RowImageTextProps {
  text: string;
  imagePath?: string;
  svgIcon?: React.ReactNode;
}

const useStyles = makeStyles({
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "8px 0px",
  },
  iconContainer: {
    width: 20,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  text: {
    marginLeft: 4,
  },
});

const RowImageText = ({ imagePath, svgIcon, text }: RowImageTextProps) => {
  const classes = useStyles();

  return (
    <div className={classes.row}>
      <div className={classes.iconContainer}>
        {!!imagePath && <img src={imagePath}></img>}
        {!!svgIcon && svgIcon}
      </div>
      <Typography
        className={classes.text}
        variant="body2"
        color="text.secondary"
      >
        {text}
      </Typography>
    </div>
  );
};

export default RowImageText;

import axios from "axios";

export async function getEventDetail({
  eventId,
}: {
  eventId: string;
}): Promise<Service.Events.Event> {
  return axios
    .get<Service.Events.Event>(
      `${process.env.FIREBASE_API_URL}/event/${eventId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      if (response.status === 200 && response.data) {
        return response.data;
      }
      throw { response };
    })
    .catch((error) => {
      throw error;
    });
}

export async function getConsultantDetail({
  consultantId,
}: {
  consultantId: string;
}): Promise<Service.Consultants.Consultant> {
  return axios
    .get<Service.Consultants.Consultant>(
      `${process.env.BACKEND_API_URL}/consultant/${consultantId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      if (response.status === 200 && response.data) {
        return response.data;
      }
      throw { response };
    })
    .catch((error) => {
      throw error;
    });
}

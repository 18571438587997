import "./index.scss";
import React from "react";
import {Helmet} from "react-helmet";
import { useParams } from "react-router-dom";
import useMounted from "../../use-mounted";
import ResponsiveAppBar from "../header";
import { getConsultantDetail } from "../../service";
import Loader from "../ui/loader";
import ConsultantView from "../ui/consultantView";

const ConsultantScreen = () => {
  let params: any = useParams();
  const isMounted = useMounted();

  const [state, setState] = React.useState<{
    loading: boolean;
    consultant?: Service.Consultants.Consultant;
    error?: any;
  }>({
    loading: false,
    consultant: undefined,
    error: undefined,
  });

  const getQueryParam = (field:string) => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    return params.get(field);
  }

  React.useEffect(() => {
    setState({ ...state, loading: true });
    const queryId = getQueryParam("id");
    console.log("queryId: ", queryId);
    const queryCountryBrandId = getQueryParam("countryBrandId");
    console.log("queryCountryBrandId: ", queryCountryBrandId);
    const consultantId = params["id"] ?? queryId;
    const countryBrandId = params["countryBrandId"] ?? queryCountryBrandId;
    console.log("consultantId: ", consultantId);
    console.log("countryBrandId: ", countryBrandId);
    getConsultantDetail({ consultantId: consultantId })
    .then((result) => {
      console.log("RESULT: ", result);
      setState({ ...state, loading: false, consultant: result });
    })
    .catch((err) => {
      console.log("detail error ", err);
      setState({ ...state, loading: false, error: err });
    });

    return () => {};
  }, [isMounted]);


  return (
    <div className="screen consultant">
       {!!state.consultant && 
        (<Helmet>
          <meta charSet="utf-8" />
          <title>{state.consultant.fullName}</title>
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content={state.consultant.fullName} />
          <meta property="og:description" content={state.consultant.description} />
          <meta property="og:image" content={state.consultant.picture} />
          {/* <meta property="og:url" content={state.consultant.sharingUrl} /> */}
          <meta property="og:locale" content="it_IT" />
          <meta property="og:site_name" content="My Beauty Whisper" />
          <meta property="fb:app_id" content="2132397663499456" />
        </Helmet>)
       }
      <ResponsiveAppBar />
      <main className="content">
        {state.consultant && <ConsultantView consultant={state.consultant}></ConsultantView>}
      </main>
      <Loader show={state.loading}></Loader>
    </div>
  );
};

export default ConsultantScreen;

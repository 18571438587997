import * as React from "react";
import {
Button,
TextField,
CircularProgress,
Grid
} from "@mui/material";
import './index.scss';
import * as firebase from '../../configuration/firebase';
import { useHistory } from "react-router-dom";


interface ILoginState{
    email:string,
    password:string
}


const LoginScreen = () => {

    const [state , setState] = React.useState<ILoginState>({
        email : "",
        password : ""
    })
    const [loading,setLoading] = React.useState<boolean>(false);
    const history = useHistory();
    
    const _handleChange = (event:React.ChangeEvent<HTMLInputElement>) => {
        if(!!!event) return;
        const key = event.target.name;
        const value = event.target.value ?? "";
        const newState = {... state};
        // @ts-ignore: Unreachable code error
        newState[key] = value;
        setState(newState);
    };

    const _showLoader = () => {
        return <div className="screen loading">
        <CircularProgress />
      </div>
    }

    const _handleLogin = async() => {
        setLoading(true);
        const {email,password} = state;
        const auth = firebase.getAuth();
        try{
            const userCredential = await firebase.signInWithEmailAndPassword(auth,email,password);
            setLoading(false);
            if(!!userCredential){
                history.push("/");
            }   
        }catch(error){
            setLoading(false);
            alert((error as Error).message ?? "Generic Error");
        }
    }

    return (
        <div className="screen login">
            <div className="container_form">
                <div className="form">
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center">
                        <Grid item xs={12}><br/>
                            <TextField name="email" label="Mail" onChange={_handleChange} type={'email'}></TextField>
                        </Grid>
                        <Grid item xs={12}><br/>
                            <TextField name="password" label="Password"  onChange={_handleChange} type={'password'}></TextField>
                        </Grid>
                        <Grid item xs={12}><br/><br/>
                            <Button variant="outlined" fullWidth onClick={_handleLogin} > Login </Button>
                        </Grid>
                        </Grid>
                </div>
            </div> 
            {loading && _showLoader()}
        </div>
    )
}




export default LoginScreen;
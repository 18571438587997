import React from "react";
import { makeStyles } from "@mui/styles";
import {
  Typography,
  CircularProgress,
  CircularProgressProps,
} from "@mui/material";

const useStyles = makeStyles({
  container: {
    position: "fixed",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    zIndex: "3000000000 !important" as any,
  },
  opaque: {
    background: "rgba(255,255,255,0.7)",
  },
  logoContainer:{
    width: 95,
    height: 95,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  logo:{
    width:80
  },
  message: {
    maxWidth: 400,
    margin: 20,
    textAlign: "center",
  },
  root: {
    zIndex: "3000000000 !important" as any,
    position:"absolute",
    color:"#e32679"
  },
});

export interface LoaderProps extends CircularProgressProps {
  show: boolean;
  opaque?: boolean;
  message?: string;
}

const Loader = (props: LoaderProps) => {
  const showOpaque = props.opaque ?? true;
  const classes = useStyles();
  const { root, ...otherClasses } = classes;
  const { show, ...defaultProps } = props;
  const backgroundClass = showOpaque
    ? `${classes.container} ${classes.opaque}`
    : otherClasses.container;
  return (
    <React.Fragment>
      {show && (
        <div className={backgroundClass}>
          <div className={classes.logoContainer}>
            <CircularProgress size={95} classes={{ root }} {...defaultProps} />
            <img
              className={classes.logo}
              src="/assets/images/logo_toolbar@2x.png"
            ></img>
          </div>

          {!!props.message && (
            <Typography className={otherClasses.message}>
              {props.message}
            </Typography>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default Loader;

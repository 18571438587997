import "./index.scss";
import React from "react";
import {Helmet} from "react-helmet";
import { useParams } from "react-router-dom";
import useMounted from "../../use-mounted";
import ResponsiveAppBar from "../header";
import { getEventDetail } from "../../service";
import EventView from "../ui/eventView";
import Loader from "../ui/loader";


const Eventscreen = () => {
  let params: any = useParams();
  const isMounted = useMounted();

  const [state, setState] = React.useState<{
    loading: boolean;
    event?: Service.Events.Event;
    error?: any;
  }>({
    loading: false,
    event: undefined,
    error: undefined,
  });

  const getQueryParam = (field:string) => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    return params.get(field);
  }

  React.useEffect(() => {
    setState({ ...state, loading: true });
    const queryId = getQueryParam("id");
    const eventId = params["id"] ?? queryId;
    getEventDetail({ eventId: eventId })
      .then((result) => {
        console.log("RESULT: ", result);
        setState({ ...state, loading: false, event: result });
      })
      .catch((err) => {
        console.log("detail error ", err);
        setState({ ...state, loading: false, error: err });
      });

    return () => {};
  }, [isMounted]);


  return (
    <div className="screen event">
       {!!state.event && 
        (<Helmet>
          <meta charSet="utf-8" />
          <title>{state.event.title}</title>
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content={state.event.title} />
          <meta property="og:description" content={state.event.description} />
          <meta property="og:image" content={state.event.cover} />
          <meta property="og:url" content={state.event.sharingUrl} />
          <meta property="og:locale" content="it_IT" />
          <meta property="og:site_name" content="My Beauty Whisper" />
          <meta property="fb:app_id" content="2132397663499456" />
        </Helmet>)
       }
      <ResponsiveAppBar />
      <main className="content">
        {state.event && <EventView event={state.event}></EventView>}
      </main>
      <Loader show={state.loading}></Loader>
    </div>
  );
};

export default Eventscreen;

import { Property } from "../models";
import { createStore } from 'redux';

const defaultProperty: Property = {
    gidProperty:"166217eb-46b7-465c-b33d-2843822325e4",
    title:"MBW",  
};

export const changeProperty = (payload: Property) =>
  ({
    type: 'CHANGE_PROPERTY',
    payload,
  } as const);

export type Actions = ReturnType<typeof changeProperty>;

function reducer(state = defaultProperty, action: Actions) {
    console.log("@@@ reducer");
    console.log("@@@ start");
    console.log(action);

    switch (action.type) {
      case 'CHANGE_PROPERTY':
        const newState = { ...state, ...action.payload};
        console.log("NEW STATE");
        console.log(newState);
        return newState;
      default:
        return state;
    }
}
  
const store = createStore(reducer);

export type RootState = ReturnType<typeof store.getState>;

export default store;
import * as firebase from 'firebase/app';
import { getAnalytics } from "firebase/analytics";


const firebaseConfig = {
  apiKey: "AIzaSyBQiFIbQng0zJ_A5OOiP6NIvcTDW_quT2Q",
  authDomain: "mybeautywhisper-prod.firebaseapp.com",
  databaseURL: "https://mybeautywhisper-prod.firebaseio.com",
  projectId: "mybeautywhisper-prod",
  storageBucket: "mybeautywhisper-prod.appspot.com",
  messagingSenderId: "999492269568",
  appId: "1:999492269568:web:8615f85374d386c7ab8ae5",
  measurementId: "G-VLFZZVKMML"
};

export const app = firebase.initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export * from '@firebase/auth';